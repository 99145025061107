var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "contenedor-titulo" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "botones" },
          [
            !_vm.$usuarioEs.supervisor()
              ? _c(
                  "el-button",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.cargandoExcel,
                        expression: "cargandoExcel"
                      }
                    ],
                    attrs: { type: "default" },
                    on: { click: _vm.descargarExcel }
                  },
                  [_vm._v("\n        Exportar a Excel\n      ")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("el-card", { attrs: { "body-style": { padding: "20px" } } }, [
        _c(
          "div",
          [
            _c(
              "maca-datatable",
              {
                attrs: {
                  url: _vm.urlTabla,
                  params: _vm.paramsTabla,
                  actualizar: _vm.actualizarTabla,
                  bloquear: _vm.bloquearTabla
                },
                on: {
                  "update:actualizar": function($event) {
                    _vm.actualizarTabla = $event
                  },
                  "update:bloquear": function($event) {
                    _vm.bloquearTabla = $event
                  }
                }
              },
              [
                _c(
                  "el-table-column",
                  {
                    attrs: { width: 100 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$common.formatearFecha(props.row.fecha)
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-rangofecha", {
                          attrs: { label: "Fecha" },
                          model: {
                            value: _vm.filtroFecha,
                            callback: function($$v) {
                              _vm.filtroFecha = $$v
                            },
                            expression: "filtroFecha"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Hora", width: 70 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$common.formatearHora(props.row.hora)
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 80 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.puntoVenta)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-buscar", {
                          attrs: {
                            label: "Punto de Venta",
                            urlSelect: _vm.urlPuntoVenta
                          },
                          model: {
                            value: _vm.filtroPuntoVenta,
                            callback: function($$v) {
                              _vm.filtroPuntoVenta = $$v
                            },
                            expression: "filtroPuntoVenta"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Teléfono", width: 110 },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                props.row.detallePuntoVenta.duenioTelefono
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Es Secco", width: 110 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            props.row.compradorSecco
                              ? _c("b", { staticStyle: { color: "#ec2127" } }, [
                                  _vm._v("Si")
                                ])
                              : _c("span", [_vm._v("No")])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-sino", {
                          attrs: { label: "Es Secco" },
                          model: {
                            value: _vm.filtroCompradorSecco,
                            callback: function($$v) {
                              _vm.filtroCompradorSecco = $$v
                            },
                            expression: "filtroCompradorSecco"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Visitado", width: 130 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c(
                              "div",
                              { staticStyle: { display: "flex" } },
                              [
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "item",
                                    attrs: {
                                      effect: "dark",
                                      content: "Registrar que ya fue visitado",
                                      placement: "bottom-start"
                                    }
                                  },
                                  [
                                    !props.row.detallePuntoVenta
                                      .contactarRealizado &&
                                    props.row.detallePuntoVenta.contactar
                                      ? _c("el-button", {
                                          staticStyle: { margin: "auto" },
                                          attrs: {
                                            type: "success",
                                            size: "small",
                                            plain: "",
                                            round: "",
                                            icon: "el-icon-check"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.marcarVisitado(
                                                props.row.puntoVentaID
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                props.row.detallePuntoVenta.contactarRealizado
                                  ? _c("el-button", {
                                      staticStyle: {
                                        margin: "auto",
                                        "font-size": "20px"
                                      },
                                      attrs: {
                                        type: "text",
                                        size: "small",
                                        icon: "el-icon-check"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-visitado", {
                          attrs: { label: "Visitado" },
                          model: {
                            value: _vm.filtroVisitado,
                            callback: function($$v) {
                              _vm.filtroVisitado = $$v
                            },
                            expression: "filtroVisitado"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Tipo Comprador", width: 160 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return _vm._l(props.row.tipoCompradorSecco, function(
                            item,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: index },
                              [
                                _c("el-tag", {
                                  staticStyle: {
                                    "margin-top": "3px",
                                    "margin-bottom": "3px"
                                  },
                                  attrs: { type: "info" },
                                  domProps: { textContent: _vm._s(item.nombre) }
                                })
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: {
                            label: "Tipo Comprador",
                            urlSelect: _vm.urlTipoComprador,
                            multiple: ""
                          },
                          model: {
                            value: _vm.filtroTipoComprador,
                            callback: function($$v) {
                              _vm.filtroTipoComprador = $$v
                            },
                            expression: "filtroTipoComprador"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 80 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.empleado)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select-persona", {
                          attrs: {
                            label: "Empleado",
                            urlSelect: _vm.urlEmpleado
                          },
                          model: {
                            value: _vm.filtroEmpleado,
                            callback: function($$v) {
                              _vm.filtroEmpleado = $$v
                            },
                            expression: "filtroEmpleado"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c(
                  "el-table-column",
                  {
                    attrs: { "min-width": 50 },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(
                                  props.row.detallePuntoVenta
                                    ? props.row.detallePuntoVenta.provincia
                                    : ""
                                )
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: {
                            label: "Provincia",
                            urlSelect: _vm.urlProvincia
                          },
                          model: {
                            value: _vm.filtroProvincia,
                            callback: function($$v) {
                              _vm.filtroProvincia = $$v
                            },
                            expression: "filtroProvincia"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Localidad" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                props.row.detallePuntoVenta
                                  ? props.row.detallePuntoVenta.localidad
                                  : ""
                              )
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _c(
                  "el-table-column",
                  {
                    attrs: { label: "Tipo Compra" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(props) {
                          return [
                            _c("span", {
                              domProps: {
                                textContent: _vm._s(props.row.tipoCompra)
                              }
                            })
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "header" },
                      [
                        _c("maca-datatable-filtrar-select", {
                          attrs: {
                            label: "Tipo Compra",
                            urlSelect: _vm.urlTipoCompra
                          },
                          model: {
                            value: _vm.filtroTipoCompra,
                            callback: function($$v) {
                              _vm.filtroTipoCompra = $$v
                            },
                            expression: "filtroTipoCompra"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  2
                ),
                _c("el-table-column", {
                  attrs: { label: "Ver", width: "70" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function(props) {
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "success",
                                size: "small",
                                plain: "",
                                round: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.modalVer.abrir(props.row.id)
                                }
                              }
                            },
                            [_c("i", { staticClass: "el-icon-view" })]
                          )
                        ]
                      }
                    }
                  ])
                }),
                !_vm.$usuarioEs.supervisor()
                  ? _c("el-table-column", {
                      attrs: { label: "Borrar", width: "70" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(props) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "danger",
                                      size: "small",
                                      plain: "",
                                      round: ""
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.eliminar(props.row.id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "el-icon-delete" })]
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2934779640
                      )
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("modal-ver", {
        ref: "modalVer",
        on: {
          "actualizar-tabla": function($event) {
            _vm.actualizarTabla = true
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titulo" }, [
      _c("h3", [
        _c("i", { staticClass: "el-icon-s-claim" }),
        _vm._v("Relevamientos")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }