<template>
  <div>
    <div class="contenedor-titulo">
      <div class="titulo">
        <h3><i class="el-icon-s-claim"></i>Relevamientos</h3>
      </div>
      <div class="botones">
        <el-button
          type="default"
          @click="descargarExcel"
          v-loading="cargandoExcel"
          v-if="!$usuarioEs.supervisor()"
        >
          Exportar a Excel
        </el-button>
      </div>
    </div>
    <el-card :body-style="{ padding: '20px' }">
      <div>
        <maca-datatable
          :url="urlTabla"
          :params="paramsTabla"
          :actualizar.sync="actualizarTabla"
          :bloquear.sync="bloquearTabla"
        >
          <el-table-column :width="100">
            <template slot="header">
              <maca-datatable-filtrar-rangofecha
                label="Fecha"
                v-model="filtroFecha"
              ></maca-datatable-filtrar-rangofecha>
            </template>
            <template slot-scope="props">
              <span v-text="$common.formatearFecha(props.row.fecha)"></span>
            </template>
          </el-table-column>
          <el-table-column label="Hora" :width="70">
            <template slot-scope="props">
              <span v-text="$common.formatearHora(props.row.hora)"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="80">
            <template slot="header">
              <maca-datatable-filtrar-buscar
                label="Punto de Venta"
                v-model="filtroPuntoVenta"
                :urlSelect="urlPuntoVenta"
              ></maca-datatable-filtrar-buscar>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.puntoVenta"></span>
            </template>
          </el-table-column>
          <el-table-column label="Teléfono" :width="110">
            <template slot-scope="props">
              <span v-text="props.row.detallePuntoVenta.duenioTelefono"></span>
            </template>
          </el-table-column>
          <el-table-column label="Es Secco" :width="110">
            <template slot="header">
              <maca-datatable-filtrar-sino
                label="Es Secco"
                v-model="filtroCompradorSecco"
              ></maca-datatable-filtrar-sino>
            </template>
            <template slot-scope="props">
              <b v-if="props.row.compradorSecco" style="color: #ec2127">Si</b>
              <span v-else>No</span>
            </template>
          </el-table-column>
          <el-table-column label="Visitado" :width="130">
            <template slot="header">
              <maca-datatable-filtrar-visitado
                label="Visitado"
                v-model="filtroVisitado"
              ></maca-datatable-filtrar-visitado>
            </template>
            <template slot-scope="props">
              <div style="display: flex">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Registrar que ya fue visitado"
                  placement="bottom-start"
                >
                  <el-button
                    style="margin: auto"
                    v-if="
                      !props.row.detallePuntoVenta.contactarRealizado &&
                        props.row.detallePuntoVenta.contactar
                    "
                    type="success"
                    size="small"
                    plain
                    round
                    icon="el-icon-check"
                    @click="marcarVisitado(props.row.puntoVentaID)"
                  ></el-button>
                </el-tooltip>
                <el-button
                  style="margin: auto; font-size: 20px"
                  v-if="props.row.detallePuntoVenta.contactarRealizado"
                  type="text"
                  size="small"
                  icon="el-icon-check"
                ></el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="Tipo Comprador" :width="160">
            <template slot="header">
              <maca-datatable-filtrar-select
                label="Tipo Comprador"
                v-model="filtroTipoComprador"
                :urlSelect="urlTipoComprador"
                multiple
              ></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <div
                v-for="(item, index) in props.row.tipoCompradorSecco"
                :key="index"
              >
                <el-tag
                  style="margin-top: 3px; margin-bottom: 3px"
                  type="info"
                  v-text="item.nombre"
                ></el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column :min-width="80">
            <template slot="header">
              <maca-datatable-filtrar-select-persona
                label="Empleado"
                v-model="filtroEmpleado"
                :urlSelect="urlEmpleado"
              ></maca-datatable-filtrar-select-persona>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.empleado"></span>
            </template>
          </el-table-column>
          <el-table-column :min-width="50">
            <template slot="header">
              <maca-datatable-filtrar-select
                label="Provincia"
                v-model="filtroProvincia"
                :urlSelect="urlProvincia"
              ></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <span
                v-text="
                  props.row.detallePuntoVenta
                    ? props.row.detallePuntoVenta.provincia
                    : ''
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="Localidad">
            <template slot-scope="props">
              <span
                v-text="
                  props.row.detallePuntoVenta
                    ? props.row.detallePuntoVenta.localidad
                    : ''
                "
              ></span>
            </template>
          </el-table-column>
          <el-table-column label="Tipo Compra">
            <template slot="header">
              <maca-datatable-filtrar-select
                label="Tipo Compra"
                v-model="filtroTipoCompra"
                :urlSelect="urlTipoCompra"
              ></maca-datatable-filtrar-select>
            </template>
            <template slot-scope="props">
              <span v-text="props.row.tipoCompra"></span>
            </template>
          </el-table-column>
          <el-table-column label="Ver" width="70">
            <template slot-scope="props">
              <el-button
                type="success"
                size="small"
                @click="$refs.modalVer.abrir(props.row.id)"
                plain
                round
              >
                <i class="el-icon-view"></i>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!$usuarioEs.supervisor()"
            label="Borrar"
            width="70"
          >
            <template slot-scope="props">
              <el-button
                type="danger"
                size="small"
                @click="eliminar(props.row.id)"
                plain
                round
              >
                <i class="el-icon-delete"></i>
              </el-button>
            </template>
          </el-table-column>
        </maca-datatable>
      </div>
    </el-card>
    <modal-ver
      ref="modalVer"
      @actualizar-tabla="actualizarTabla = true"
    ></modal-ver>
  </div>
</template>

<script>
import ModalVer from "./modales/ver";
import MacaDatatableFiltrarSino from "./filtrar_sino";
import GeneradorExcelTabla from "./generar_excel.js";
import MacaDatatableFiltrarVisitado from "../puntos_venta/filtrar_visitado.vue";

export default {
  name: "relevamiento",
  components: {
    MacaDatatableFiltrarVisitado,
    ModalVer,
    MacaDatatableFiltrarSino,
  },
  data() {
    return {
      urlTabla: this.$api.URL + "/relevamiento/obtenerTodos",
      paramsTabla: [],
      actualizarTabla: true,
      bloquearTabla: true,
      cargandoExcel: false,

      urlEmpleado: this.$api.URL + "/empleado/obtenerTodos",
      urlPuntoVenta: this.$api.URL + "/puntoVenta/obtenerTodos",
      urlTipoComprador:
        this.$api.URL + "/tipoCompradorSecco/obtenerTodosSelect",
      urlTipoCompra: this.$api.URL + "/tipoCompra/obtenerTodosSelect",
      urlProvincia: this.$api.URL + "/provincia/obtenerTodos?paisID=1",

      filtroEmpleado: null,
      filtroPuntoVenta: null,
      filtroFecha: null,
      filtroCompradorSecco: null,
      filtroTipoComprador: null,
      filtroTipoCompra: null,
      filtroProvincia: null,
      filtroVisitado: null,
    };
  },
  created() {
    let hoy = this.$moment(new Date()).format("YYYY-MM-DD");
    let sieteDiasAtras = this.$moment(new Date())
      .subtract(7, "days")
      .format("YYYY-MM-DD");

    this.filtroFecha = [sieteDiasAtras, hoy];

    this.$common.agregarUrlParam(
      this.paramsTabla,
      "fechaDesde",
      this.filtroFecha[0]
    );
    this.$common.agregarUrlParam(
      this.paramsTabla,
      "fechaHasta",
      this.filtroFecha[1]
    );
  },
  methods: {
    async eliminar(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea eliminar el relevamiento."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { relevamientoID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/relevamiento/eliminar",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Relevamiento borrado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
    async descargarExcel() {
      let url = this.$api.URL + "/relevamiento/obtenerTodos?exportar=1";
      let separador = "&";

      if (this.filtroFecha != null) {
        url = url + separador + "fechaDesde=" + this.filtroFecha[0];
        separador = "&";
        url = url + separador + "fechaHasta=" + this.filtroFecha[1];
        separador = "&";
      }

      if (this.filtroEmpleado != null) {
        url = url + separador + "empleadoID=" + this.filtroEmpleado.id;
        separador = "&";
      }
      if (this.filtroPuntoVenta != null) {
        url = url + separador + "puntoVentaID=" + this.filtroPuntoVenta.id;
        separador = "&";
      }
      if (this.filtroCompradorSecco != null) {
        url = url + separador + "compradorSecco=" + this.filtroCompradorSecco;
        separador = "&";
      }
      if (
        this.filtroTipoComprador != null &&
        this.filtroTipoComprador.length > 0
      ) {
        url =
          url +
          separador +
          "tipoCompradorSecco=" +
          JSON.stringify(this.filtroTipoComprador);
        separador = "&";
      }
      if (this.filtroProvincia != null) {
        url =
          url +
          separador +
          "detalleProvincia=" +
          JSON.stringify([
            {
              id: this.filtroProvincia.id,
            },
          ]);
        separador = "&";
      }

      this.cargandoExcel = true;

      this.$message({
        message: "Procesando datos. Por favor espere hasta obtener el Excel.",
        type: "info",
        icon: "el-icon-info",
        showClose: true,
        duration: 0,
      });

      let respuestaApi = await this.$api.get(url, this.$usuarioToken(), 0);

      this.cargandoExcel = false;

      GeneradorExcelTabla.run(
        //form
        {
          nombreReporte: "Relevamientos",
          rangoFechas: this.filtroFecha,
          esSecco: this.filtroCompradorSecco,
          tipoComprador: this.filtroTipoComprador,
          puntoVenta: this.filtroPuntoVenta,
          empleado: this.filtroEmpleado,
        },
        // data
        respuestaApi.datos
      );
    },
    async marcarVisitado(id) {
      let confirmado = await this.$confirm(
        "Confirme que desea marcar el punto de venta como visitado."
      );

      if (!confirmado) {
        return;
      }

      this.bloquearTabla = true;

      // Hacer post
      let params = { puntoVentaID: id };

      let respuestaApi = await this.$api.post(
        this.$api.URL + "/puntoVenta/actualizarContactado",
        params,
        this.$usuarioToken()
      );
      //

      this.actualizarTabla = true;

      // Procesar respuesta
      if (respuestaApi.estado == 1) {
        this.$message({
          message: "¡Punto de Venta marcado como contactado con éxito!",
          type: "success",
        });
      } else {
        this.$message({
          message: respuestaApi.excepcion,
          type: "error",
          showClose: true,
          duration: 0,
        });
      }
      //
    },
  },
  watch: {
    filtroEmpleado() {
      if (this.filtroEmpleado != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "empleadoID",
          this.filtroEmpleado.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "empleadoID");
      }

      this.actualizarTabla = true;
    },
    filtroPuntoVenta() {
      if (this.filtroPuntoVenta != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "puntoVentaID",
          this.filtroPuntoVenta.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "puntoVentaID");
      }

      this.actualizarTabla = true;
    },
    filtroFecha() {
      if (this.filtroFecha != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "fechaDesde",
          this.filtroFecha[0]
        );
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "fechaHasta",
          this.filtroFecha[1]
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "fechaDesde");
        this.$common.sacarUrlParam(this.paramsTabla, "fechaHasta");
      }

      this.actualizarTabla = true;
    },
    filtroCompradorSecco() {
      if (this.filtroCompradorSecco != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "compradorSecco",
          this.filtroCompradorSecco
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "compradorSecco");
      }

      this.actualizarTabla = true;
    },
    filtroTipoComprador() {
      if (this.filtroTipoComprador != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "tipoCompradorSecco",
          JSON.stringify(this.filtroTipoComprador)
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "tipoCompradorSecco");
      }

      this.actualizarTabla = true;
    },
    filtroTipoCompra() {
      if (this.filtroTipoCompra != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "tipoCompraID",
          this.filtroTipoCompra.id
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "tipoCompraID");
      }

      this.actualizarTabla = true;
    },
    filtroProvincia() {
      if (this.filtroProvincia != null) {
        this.$common.agregarUrlParam(
          this.paramsTabla,
          "detalleProvincia",
          JSON.stringify([
            {
              id: this.filtroProvincia.id,
            },
          ])
        );
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "detalleProvincia");
      }

      this.actualizarTabla = true;
    },
    filtroVisitado() {
      if (this.filtroVisitado != null) {
        if (this.filtroVisitado == "AV") {
          this.$common.agregarUrlParam(this.paramsTabla, "contactar", 1);
          this.$common.sacarUrlParam(this.paramsTabla, "contactarRealizado");
        } else if (this.filtroVisitado == "AVNV") {
          this.$common.agregarUrlParam(this.paramsTabla, "contactar", 1);
          this.$common.agregarUrlParam(
            this.paramsTabla,
            "contactarRealizado",
            0
          );
        } else {
          this.$common.sacarUrlParam(this.paramsTabla, "contactar");
          this.$common.agregarUrlParam(
            this.paramsTabla,
            "contactarRealizado",
            1
          );
        }
      } else {
        this.$common.sacarUrlParam(this.paramsTabla, "contactar");
        this.$common.sacarUrlParam(this.paramsTabla, "contactarRealizado");
      }

      this.$emit("filtroVisitadoCambiado", this.filtroVisitado);
      this.actualizarTabla = true;
    },
  },
};
</script>
