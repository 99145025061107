import XLSX from "xlsx";
import moment from "moment";

function run(form, datos) {
  /* original data */
  var filename = form.nombreReporte + ".xlsx";
  var data = [];
  var ws_name = "Hoja 1";

  if (datos == null) {
    return;
  }

  data.push(["Reporte de " + form.nombreReporte]);
  data.push([]);

  if (form.rangoFechas !== null) {
    data.push([]);
    data.push([
      "Fechas:",
      formatearFecha(form.rangoFechas[0]),
      "-",
      formatearFecha(form.rangoFechas[1]),
    ]);
  }
  if (form.esSecco !== null) {
    data.push([]);
    if (form.esSecco == 0) {
      data.push(["De Compradores:", "Sólo los que no son de Secco"]);
    } else if (form.esSecco == 1) {
      data.push(["De Compradores:", "Sólo de Secco"]);
    }
  } else {
    data.push([]);
    data.push(["De Compradores:", "Todos los Compradores"]);
  }
  if (form.tipoComprador !== null) {
    data.push([]);
    data.push(["De Tipo Comprador:", form.tipoComprador.nombre]);
  }
  if (form.puntoVenta !== null) {
    data.push([]);
    data.push(["De Punto de Venta:", form.puntoVenta.nombre]);
  }
  if (form.empleado !== null) {
    data.push([]);
    data.push(["De Empleado:", form.empleado.nombre]);
  }

  data.push([]);
  data.push([]);

  let cabezera = [
    "Fecha",
    "Hora",
    "Teléfono",
    "Es Secco",
    "Tipo Comprador",
    "Punto de Venta",
    "Observaciones",
    "Latitud",
    "Longitud",
    "Barrio",
    "Domicilio",
    "Localidad",
    "Provincia",
    "Empleado",
    "Carga Punto Venta",
    "Producto Relevamiento",
    "Calibre Relevamiento",
    "Precio Relevamiento",
  ];

  data.push(cabezera);
  datos.forEach((fila) => {
    let esCompradorSecco = "No";
    let cargaPuntoVenta = "No";

    if (fila.compradorSecco) {
      esCompradorSecco = "Si";
    }

    if (fila.cargaPuntoVenta) {
      cargaPuntoVenta = "Si";
    }

    let compradorSecco = "";

    if (fila.tipoCompradorSecco) {
      fila.tipoCompradorSecco.forEach((comprador) => {
        compradorSecco = compradorSecco + comprador.nombre + ", ";
      });
      compradorSecco = compradorSecco.substr(0, compradorSecco.length - 2);
    }

    fila.detalleRelevamiento.forEach((relevamiento) => {
      data.push([
        formatearFecha(fila.fecha),
        formatearHora(fila.hora),
        fila.detallePuntoVenta.duenioTelefono,
        esCompradorSecco,
        compradorSecco,
        fila.puntoVenta,
        fila.observaciones,
        fila.latitud,
        fila.longitud,
        fila.detallePuntoVenta.barrio,
        getDireccion(fila.detallePuntoVenta),
        getLocalidad(fila.detallePuntoVenta),
        getProvincia(fila.detallePuntoVenta),
        fila.empleado,
        cargaPuntoVenta,
        relevamiento.marca,
        relevamiento.productoCalibre,
        relevamiento.precio,
      ]);
    });
  });

  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.aoa_to_sheet(data);

  /* add worksheet to workbook */
  XLSX.utils.book_append_sheet(wb, ws, ws_name);

  /* write workbook */
  XLSX.writeFile(wb, filename);
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFechaMes(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("MMMM YYYY");
}

// Dada una fecha que moment pueda procesar,
// devolver una fecha con formato DD/MM/YYYY
function formatearFecha(fecha) {
  if (fecha == "" || fecha == null) {
    return "";
  }

  let date = moment(fecha);
  if (!date.isValid()) {
    date = moment(fecha, "YYYY-MM-DD");
  }

  if (!date.isValid()) {
    return "";
  }

  return date.format("DD/MM/YYYY");
}

// Dada una hora de formato hh:mm:ss, devolver hh:mm
function formatearHora(hora) {
  if (hora == null) {
    return;
  }
  return hora.substr(0, 5);
}

function getDireccion(detallePuntoVenta) {
  let val =
    detallePuntoVenta.domicilio == null ? "" : detallePuntoVenta.domicilio;

  if (val.trim() == ",  ()") {
    return "Sin Asignar";
  } else {
    return val;
  }
}

function getLocalidad(detallePuntoVenta) {
  let val =
    detallePuntoVenta.localidad == null ? "" : detallePuntoVenta.localidad;

  if (val.trim() == ",  ()") {
    return "";
  } else {
    return val;
  }
}

function getProvincia(detallePuntoVenta) {
  let val =
    detallePuntoVenta.provincia == null ? "" : detallePuntoVenta.provincia;

  if (val.trim() == ",  ()") {
    return "";
  } else {
    return val;
  }
}

export default {
  run,
};
